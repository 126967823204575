<!--
 * @Author: your name
 * @Date: 2021-04-25 14:09:09
 * @LastEditTime: 2021-05-29 17:39:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/History.vue
-->
<template>
	<div class="history">
		<Header :baseInfo="baseInfo"/>
		<div class="swiper_banner" v-if="banner.length > 0" @mouseenter="on_swiper_enter" @mouseleave="on_swiper_leave">
			<swiper ref="mySwiperBanner" :options="swiperBanner" v-if="banner.length > 1">
				<swiper-slide v-for="(item , index) in banner" :key="index">
					<img style="width:100%;height:100%;" :src="item.pic" alt="">
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<img @click="adDetail(banner[0].url)" style="width:100%;" v-else :src="banner[0].pic" alt="">
		</div>
		<div class="content">
			<h4 class="home_title">发展历程</h4>
			<p class="sub_des">六品堂十年大事件</p>
			<div class="time_line margin_auto width_1200">
				<div class="time_first d_flex flex_column align_items_center">
					<img :src="baseImgUrl + 'time_first.png'" alt="">
					<b></b>
				</div>
				<div class="d_flex time_line_item" v-for="(item , index) in history" :key="index">
					<span class="time">{{item.time}}</span>
					<div class="time_circle d_flex flex_column align_items_center">
						<img :src="baseImgUrl + 'time_circle.png'" alt="">
						<b></b>
					</div>
					<div class="time_content">
						<p v-for="(i , idx) in item.content" :key="idx">{{i}}</p>
					</div>
				</div>
			</div>
			<div class="walk_lp_edu before" :style="'background:url('+baseImgUrl+'history_video_bg.png);background-size:cover;'">
				<h4>走进六品书院</h4>
				<div class="videos_container width_1200 margin_auto">
					<swiper ref="mySwiper" :options="swiperOption" class="video_swiper">
						<swiper-slide v-for="(item , index) in videoList" :key="item.id">
							<div class="video_box">
								<video :src="item.url" :controls="index === activeIndex ? true : false"></video>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League @openPop="openPop()" :baseInfo="baseInfo"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>

<script>
import {config} from "../config";
import mixin from '../utils/mixin';
let vm = null;
export default {
	mixins:[mixin],
	components:{
		"Form":()=>import('../components/Form')
	},
	data () {
		return {
			activeIndex:0,
			baseImgUrl:config.baseImgUrl,
			history:[
				{time:'2020年' , content:['“六品书院”正式开放对外加盟授权，短短半年盟校突破400家' , ' 李六军当选浙江省硬笔书法家协会副主席' , '[当代书法艺术院培训中心]落户六品堂' , '李六军当选央视书画频道浙江站站长' , '[中央数字电视书画频道]入驻六品堂']},
				{time:'2019年' , content:['成立六品堂印刷厂，在义乌组建成熟的自动化供应链系统' , '前全国硬笔书法家协会会长王正良先生与六品书院合作' , '天猫店全年销售额突破2.5亿' , '创新中心立项掌上碑帖']},
				{time:'2018年' , content:['六品堂开设线下实体体验店' , 'CCTV发现之旅拍摄六品堂纪录片' , '六品书院在杭州布局线下直营店']},
				{time:'2017年' , content:['李六军先生创立六品书院，正式转型书法教育' , '六品堂签约著名演员王刚先生为代言人' , '六品堂天猫行业市场份额超过50%']},
				{time:'2014年' , content:['六品堂天猫店销量单店第一']},
				{time:'2010年' , content:['六品堂得到时任市长蔡奇倡导的创业基金扶持']},
				{time:'2009年' , content:['六品堂创始人李六军创立六品堂']}
			],
			videoList:[
				{url:'https://file.liupinshuyuan.com/taivideo.mp4' , id:1},
				{url:'https://file.liupinshuyuan.com/jpvideo.mp4' , id:2},
				{url:'https://file.liupinshuyuan.com/haozhi.mp4' , id:3},
			],
			swiperOption: {
				slidesPerView: 3,
				loop: true,
				autoplay: false,
				centeredSlides: true,
				initialSlide:0,
				grabCursor:true,
				on:{
					slideChangeTransitionEnd(){
						vm.activeIndex = this.realIndex
					}
				}
			},
		};
	},

	created(){
		vm = this;
	},

	methods: {},

	computed : {
		swiper(){
			return this.$refs.mySwiper.$swiper
		}
	}

}
</script>
<style lang='scss' scoped>
	.history{
		.history_banner{
			width:100%;
		}
		.content{
			.home_title{
				margin-top:100px;
			}
			.sub_des{
				color: #B91829;
				font-size: 20px;
				margin-top:20px;
			}
			.time_line{
				margin-top:20px;
				.time_first{
					position: relative;
					left:-454px;
					img{
						width: 80px;
						height:80px;
					}
					b{
						width: 2px;
						height:60px;
						background: #B91829;
						display: block;
					}
				}
				.time_line_item{
					.time{
						font-size: 28px;
						font-weight: bold;
						color:#333;
					}
					.time_circle{
						margin:0 100px 0 40px;
						img{
							width:24px;
							height:24px;
						}
						b{
							display: block;
							width:2px;
							height:100%;
							background: #B91829;
						}
					}
					.time_content{
						font-size: 24px;
						line-height: 36px;
						color:#333;
						margin-bottom:110px;
						p{
							text-align: left;
						}
					}
					.time_content:last-child{
						margin-bottom:60px;
					}
				}
			}
			.walk_lp_edu{
				width:100%;
				height:640px;
				margin-top:150px;
				h4{
					font-size: 36px;
					font-weight: bold;
					text-align: center;
					margin-top:88px;
					color:#fff;
				}
				.videos_container{
					margin-top:50px;
					height:440px;
					display: flex;
					align-items: center;
					overflow: hidden;
					.video_swiper{
						width:100%;
						height:280px;
						overflow: visible;
						.swiper-slide{
							display: flex;
							justify-content: center;
							align-items: center;
							transition: 300ms;
	  						// transform: scale(0.9);
							.video_box{
								height:100%;
								video{
									width:100%;
									height:100%;
									object-fit:contain;
									background: #000;
								}
							}
						}
						.swiper-slide-active,.swiper-slide-duplicate-active{
							transform: scale(1.4);
							z-index: 3;
							.video_box{
								border:3px solid #B91829;
							}
						}
					}
				}
			}
		}
	}
</style>
